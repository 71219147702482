<template>
  <div class="root">
    <div class="about text-center">
      <h1 class="main_title">{{ lang.apropos_title }}</h1>
      <p class="my-4">
        <strong
          >{{lang.ap1}}</strong
        >
      </p>
      <hr />
      <div class="text-start">
        <h2 class="big">
          <strong
            >{{lang.ap2}}
          </strong>
        </h2>
        <p>&nbsp;</p>
        <h3><strong>{{lang.ap3}}</strong></h3>
        <p>{{lang.ap4}}
        </p>
        <p>
          {{lang.ap5}}
        </p>
        <h3><strong>{{lang.ap6}}</strong></h3>
        <p>
          {{lang.ap7}}
        </p>
        <p>
          {{lang.ap8}}
        </p>
        <h3><strong>{{lang.ap9}}</strong></h3>
        <h5><strong> {{lang.ap10}}</strong></h5>
        <p>
          {{lang.ap11}}
        </p>
        <p>
          {{lang.ap12}}
        </p>
        <h5><strong>{{lang.ap13}}</strong></h5>
        <p>{{lang.ap14}}
          
        </p>
        <p>{{lang.ap15}}
          
        </p>
        <h2 class="big">
          <strong
            >{{lang.ap16}}</strong
          >
        </h2>
        <p>{{lang.ap17}}
          
        </p>
        <h3><strong>{{lang.ap18}}</strong></h3>
        <h5>{{lang.ap19}}</h5>
        <p>{{lang.ap20}}
          
        </p>
        <h5><strong>{{lang.ap21}}</strong></h5>
        <p>
          
        </p>
        <hr />
        <p>© 2021 CFDT - {{lang.copyright}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about {
  width: 100%;
}
.big {
  font-size:1.8rem;
}
</style>

<script>
export default {
  name: "apropos",
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
  },
};
</script>